import {
    HttpRequest,
    HttpInterceptorFn,
    HttpHandlerFn,
    HttpEvent,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { NotificationService } from '@hs/ui-core-notifications';
import { Observable, catchError, throwError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
    const notificationsService = inject(NotificationService);
    return next(request).pipe(
        catchError((error) => {
            if (error.name === 'HttpErrorResponse' && !shouldCustomFormatErrorMessage(error.error?.message ?? error.message)) {
                notificationsService.notifyOfError(error.error?.message ?? error.message);
            }
            return throwError(() => error);
        }),
    );
};

function shouldCustomFormatErrorMessage(message: string): boolean {
    const customFormattedMessages = ['Circular dependency detected'];
    return customFormattedMessages.some((customMessage) => message.includes(customMessage));
}
