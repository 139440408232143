import { getPublicApiEnvironment, getServiceRoot, getEnvironment, getApmHost } from './utils';
// eslint-disable-next-line import/no-relative-parent-imports
import packageInfo from '../../package.json';

export const environment = {
    production: true,
    version: packageInfo.version,
    amber: `${getServiceRoot(window.location)}/data`,
    publicApi: `${getServiceRoot(window.location)}/${getPublicApiEnvironment(window.location)}/rest`,
    crosstalk: `${getServiceRoot(window.location)}/two-way`,
    dsrpt: `${getServiceRoot(window.location)}/dsrpt`,
    api: './named-query/api',
    apm: {
        environment: getEnvironment(window.location),
        active: true,
        host: getApmHost(window.location.hostname),
    },
};
