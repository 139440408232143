import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserEntitlements } from '../models/entitlements';

@Injectable({ providedIn: 'root' })
export class EntitlementsService {
    private clientCode: string | undefined;
    private entitlements$: Observable<UserEntitlements> | undefined;

    constructor(private readonly http: HttpClient) {}

    getEntitlements(clientCode: string): Observable<UserEntitlements> {
        if (!this.entitlements$ || this.clientCode !== clientCode) {
            this.entitlements$ = this.fetchEntitlements(clientCode).pipe(shareReplay(1));
            this.clientCode = clientCode;
        }
        return this.entitlements$;
    }

    private fetchEntitlements(clientCode: string): Observable<UserEntitlements> {
        return this.http
            .get<UserEntitlements>(`${environment.api}/${clientCode}/entitlements`)
            .pipe(catchError(() => of(noEntitlements())));
    }
}

export function noEntitlements(): UserEntitlements {
    return {
        user: { username: '' },
        canViewNamedQueries: false,
        canManageNamedQueries: false,
        canAdminNamedQueries: false,
        canManageConversableTypes: false,
        clientsWithAdminNamedQueries: [],
        clientsWithManageNamedQueries: [],
        canManageCustomFields: false,
    };
}

