import { Component, OnInit } from '@angular/core';
import { TabDescriptor } from '@hs/ui-core-layout';
import { switchMap } from 'rxjs';

import { UserEntitlements } from './shared/models/entitlements';
import { EntitlementsService } from './shared/services/entitlements.service';
import { RoutingService } from './shared/services/routing.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    version = environment.version;
    clientCode: string | undefined;
    tabDescriptors: TabDescriptor[] | undefined;

    constructor(
        private readonly routingService: RoutingService,
        private readonly entitlementsService: EntitlementsService,
    ) {}

    ngOnInit(): void {
        this.routingService.clientCode$.pipe(switchMap((clientCode) => {
            this.clientCode = clientCode;
            return this.entitlementsService.getEntitlements(clientCode);
        })).subscribe((userEntitlements: UserEntitlements) => {
            this.tabDescriptors = [
                { value: `${this.clientCode}/queries/new`, text: 'QUERY MANAGER' },
            ];

            if (userEntitlements.canManageCustomFields) {
                this.tabDescriptors.push({ value: `${this.clientCode}/fields/new`, text: 'FIELD BUILDER' });
            }
        });

        this.routingService.isRedirectedToRoot$.subscribe((isRedirectedToRoot) => {
            if (isRedirectedToRoot) {
                this.clientCode = undefined;
                this.tabDescriptors = undefined;
            }
        });
    }
}
