import { inject } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { map, Observable } from 'rxjs';

import { EntitlementsService } from '../../services/entitlements.service';

export const canAccessFieldBuilderGuard = (route: Route, segments: UrlSegment[]): Observable<boolean> => {
    const entitlementsService = inject(EntitlementsService);
    const clientCode = segments[0].path;

    return entitlementsService.getEntitlements(clientCode)
        .pipe(
            map((entitlements) => entitlements.canManageCustomFields),
        );
};
