import { ErrorHandler, Injectable } from '@angular/core';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import { NotificationService } from '@hs/ui-core-notifications';

@Injectable({ providedIn: 'root' })
export class LocalErrorHandler implements ErrorHandler {
    constructor(
        private readonly handler: ApmErrorHandler,
        private readonly notificationsService: NotificationService,
    ) {}

    handleError(error: Error): void {
        if (error.message !== undefined && error.name !== 'HttpErrorResponse') {
            this.notificationsService.notifyOfError(error.message);
        }
        this.handler.handleError(error);
    }
}
